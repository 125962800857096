














































import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import { Env } from "../../common/util/env";

const warningMessages = {
    ja_JP: 'メニューの翻訳はありません',
    en_US: 'The menu will not be translated.',
    ko_KR: '메뉴는 번역되지 않습니다.',
    zh_TW: '菜單不會被翻譯',
    zh_CN: '菜单不会被翻译',
}

export default Vue.component("SOHeader", {
  components: {
    QTranslate: () => import("../elements/QTranslate.vue")
  },
  data() {
    return {
      langPopup: false,
      warningMessages,
      appVersion: Env.APP_VERSION,
    };
  },
  computed: {
    isInitializing(): boolean {
      return store.state.isInitializing;
    },
    canBack(): boolean {
      return this.backTo != undefined;
    },
    langs() {
      return store.state.langs.filter(lang => lang.is_enable);
    },
    shopLangs() {
      return store.state.shopLangs.filter(lang => lang.is_enable);
    },
    page(): string {
      try {
        return this.$route.path.split("/")[1];
      } catch (err) {
        return "";
      }
    },
    backTo(): string | undefined {
      switch (this.page) {
        case "check":
          return "/order-history";
        case "menu":
          if (this.$route.query.effect_id) {
            return "/category";
          }

          let category_group_parent = _.find(store.state.categoryGroups, (categoryGroup) => categoryGroup.category_id === this.$route.params.category_id);

          if (category_group_parent && category_group_parent.parent_id) {
            return  `/category_group/${category_group_parent.parent_id}`;
          } else {
            return "/category";
          }
        case "order-history":
          return "/category";
        case "category_group":
          let category_group = _.find(store.state.categoryGroups, (categoryGroup) => categoryGroup.id === this.$route.params.category_group_id);

          if (category_group.parent_id) {
           return  `/category_group/${category_group.parent_id}`;
          } else {
            return "/category";
          }

        default:
          return undefined;
      }
    },
    headerClass() {
      switch (this.page) {
        case "category":
          return "Category";
      }

      return "";
    },
    title(): string {
      const langKey = store.state.langKey;
      const staticTranslate = store.state.staticTranslates[langKey];

      switch (this.page) {
        case "category_group":
          let categoryGroup = _.find(store.state.categoryGroups, (category) => category.id === this.$route.params.category_group_id);

          return categoryGroup.name;
        case "category":
          return this.shopName || "MENU";
        case "check":
          return staticTranslate["lang-0025"] || "お会計";
        case "menu":
          const categoryId = this.$route.path.split("/")[2];
          const category = _.find(
            store.state.categories,
            cat => cat.id == categoryId
          );
          return (category || { name: "" }).name;
        case "order-history":
          return staticTranslate["lang-0017"] || "注文履歴";

        default:
          return "Vivo";
      }
    },
    cate(): string {
      switch (this.page) {
        case "menu":
          const categoryId = this.$route.path.split("/")[2];
          const category = _.find(
            store.state.categories,
            cat => cat.id == categoryId
          );
          const type = (category || { type: 1 }).type;
          const typeString = {
              1: '',
              2: 'takeout',
              3: 'catering',
          };
          return typeString[type];

        default:
          return "";
      }
    },
    shopName(): string {
      return store.state.shop.name;
    },
    selectedLang: {
      get() {
        const target = _.find(
          this.langs,
          lang => lang.locale === store.state.langKey
        );
        if (!target) {
          return "---";
        }

        return target.locale;
      },
      set(selectKey: string) {
        this.langPopup = false;
        store.dispatch("selectLangKey", selectKey).then(() => {
          this.$router.push(`/checkin?code=${store.state.code}`);
        });
      }
    }
  },
  watch: {
    selectedLang() {
      this.closeLangPopup();
    },
    title() {
      this.$emit("updateTitle", `${this.title} - ${this.shopName || "Vivo"}`);
    },
    shopName() {
      this.$emit("updateTitle", `${this.title} - ${this.shopName || "Vivo"}`);
    }
  },
  methods: {
    handleClickLangPopup() {
      this.closeLangPopup();
    },

    openLangPopup() {
      this.langPopup = true;
    },
    closeLangPopup() {
      this.langPopup = false;
    },

    selectedShopLang(value): boolean {
        const target = _.find(
          this.shopLangs,
          lang => lang.locale === value
        );
        if (!target) {
          return true;
        }

        return false;
    }
  }
});
